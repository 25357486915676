import { useFormik } from 'formik';

import { redirect } from '@utils';
import { Button, Input, Offerta } from '@ui';
import { SendOtp } from '@entities/send-otp';

import { validationSchemaFormMobi } from './schema';
import { IOtpFormPhone, ISmsProps } from './types';

export const Sms = ({
  pay,
  phone,
  creditConditionIds,
  isPendingPay,
  isTokenization,
}: ISmsProps) => {
  const { values, isValid, setFieldValue, errors, handleSubmit } =
    useFormik<IOtpFormPhone>({
      initialValues: { otp: '' },
      validationSchema: validationSchemaFormMobi,
      onSubmit: ({ otp }) => {
        pay(
          {
            creditConditionId: creditConditionIds,
            confirmationCode: Number(otp),
          },
          {
            onSuccess: (data) => {
              redirect(data?.payload);
            },
          }
        );
      },
    });

  return (
    <form onSubmit={handleSubmit}>
      <p className="mb-4">
        SMS с кодом подтверждения отправлено на номер +992 {phone}
      </p>
      <Input
        mask="99999"
        maskChar=" "
        label="Введите код"
        placeholder="— — — — — —"
        errorText={errors.otp}
        initialValue={values.otp}
        isError={Boolean(errors.otp)}
        onChange={(value) => setFieldValue('otp', value)}
      />
      <div className="mt-4 flex flex-col items-center justify-center gap-2">
        <SendOtp />
      </div>
      <Button
        type="submit"
        isLoading={isPendingPay}
        className="mb-4 mt-6 w-full justify-center"
        disabled={!isValid}
      >
        {isTokenization ? 'Привязать' : 'Оплатить'}
      </Button>
      <Offerta isTokenization={isTokenization} />
    </form>
  );
};
