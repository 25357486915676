import { PropsWithChildren } from 'react';

import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { AnimatePresence, motion } from 'framer-motion';

import { If } from '@ui';

import { IAccordionProps } from './types';

export const Accordion = ({
  children,
  isOpen,
  onToggle,
  title,
}: PropsWithChildren<IAccordionProps>) => {
  return (
    <div>
      <div className="cursor-pointer py-3">
        <div onClick={onToggle} className="flex items-center justify-between">
          <p className="sm:text-md text-[16px]">{title}</p>
          <If anotherChildren={<IoIosArrowDown />} condition={isOpen}>
            <IoIosArrowUp />
          </If>
        </div>
      </div>
      <AnimatePresence>
        {isOpen && (
          <div className="py-3">
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
            >
              {children}
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </div>
  );
};
