import { useEffect, useState } from 'react';

import clsx from 'clsx';
import { SalomCardIcon } from '@icons';

import { Button, If, InfoPayment, Input } from '@ui';
import { getTypeSalomConditions, numberFormat, sortConditions } from '@utils';

import { IConditionsProps } from './types';

import { ICondition, IContractNumber } from '../../types';
import { Accordion } from '../../../../shared/ui/accordion';

// const conditions: IContractNumber[] = toCamelCaseFormat([
//   {
//     phoneNumber: '917******45',
//     session_token: '',
//     store_id: '3289204c-3b31-40de-946a-c5685efa1250',
//     store_name: 'Test Terminal Tokenization Bug Test',
//     conditions: [
//       {
//         id: 12077,
//         displayName: '9 м. - 15% - Standard 2022',
//         clientCommission: 15,
//         durationMax: 9,
//         duration_type: 'in_months',
//         available: true,
//         is_provided_by_mp: true,
//         amount: '10',
//         fee: '1.5',
//         total_amount: '11.5',
//       },
//       {
//         id: 12078,
//         displayName: '12 м. - 20% - Standard 2022',
//         clientCommission: 20,
//         durationMax: 12,
//         duration_type: 'in_months',
//         available: true,
//         is_provided_by_mp: false,
//         amount: '10',
//         fee: '2',
//         total_amount: '12',
//       },
//       {
//         id: 12079,
//         displayName: '15 м. - 24% - Standard 2022',
//         clientCommission: 21,
//         durationMax: 15,
//         duration_type: 'in_months',
//         available: true,
//         is_provided_by_mp: false,
//         amount: '10',
//         fee: '2.1',
//         total_amount: '12.1',
//       },
//       {
//         id: 12080,
//         displayName: '18 м. - 27% - Standart 2020',
//         clientCommission: 27,
//         durationMax: 18,
//         duration_type: 'in_months',
//         available: true,
//         is_provided_by_mp: false,
//         amount: '10',
//         fee: '2.7',
//         total_amount: '12.7',
//       },
//       {
//         id: 12081,
//         displayName: '24 м. - 30% - Standart 2020',
//         clientCommission: 30,
//         durationMax: 24,
//         duration_type: 'in_months',
//         available: true,
//         is_provided_by_mp: false,
//         amount: '10',
//         fee: '3',
//         total_amount: '13',
//       },
//     ],
//   },
//   {
//     phoneNumber: '917******45',
//     session_token: '',
//     store_id: 'a6ef4e04-f87c-4472-9a08-aad73cdf1040',
//     store_name: 'Терминал тестового партнёра',
//     conditions: [
//       {
//         id: 34855,
//         displayName: '3 м. - 7% - Standard 2023',
//         clientCommission: 7,
//         durationMax: 3,
//         duration_type: 'in_months',
//         available: true,
//         is_provided_by_mp: false,
//         amount: '15',
//         fee: '1.05',
//         total_amount: '16.05',
//       },
//       {
//         id: 34856,
//         displayName: '6 м. - 12% - Standard 2023',
//         clientCommission: 12,
//         durationMax: 6,
//         duration_type: 'in_months',
//         available: true,
//         is_provided_by_mp: false,
//         amount: '15',
//         fee: '1.8',
//         total_amount: '16.8',
//       },
//       {
//         id: 34857,
//         displayName: '9 м. - 17% - Standard 2023',
//         clientCommission: 17,
//         durationMax: 9,
//       },
//     ],
//   },
// ]);

export const Conditions = ({
  conditions,
  isPendingSendOtp,
  sendOtp,
  contractNumber,
  handleNextStep,
  handleChangeCreditConditionId,
}: IConditionsProps) => {
  const [isSelect, setIsSelect] = useState<boolean>(false);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [storeNames, setStoreNames] = useState<string[]>([]);

  const [selectedConditions, setSelectedConditions] = useState<
    {
      storeName: string;
      conditions: ICondition;
    }[]
  >([]);

  const conditionIds: number[] = selectedConditions.map(
    (el) => el.conditions.id
  );

  const handleOpen = (name: string) => {
    const has = storeNames.includes(name);
    if (has) {
      const filtered = storeNames.filter((store) => store !== name);
      setStoreNames(filtered);
    } else {
      setStoreNames((prev) => [...prev, name]);
    }
  };

  const handleSelectValue = (
    storeName: string,
    value: ICondition,
    index: number
  ): void => {
    const isSelectedCondition = selectedConditions.find(
      (condition) => condition.storeName === storeName
    )?.storeName;

    if (!isSelectedCondition) {
      setSelectedConditions((prev) => [
        ...prev,
        { storeName, conditions: value },
      ]);
      handleChangeCreditConditionId(conditionIds);
    } else {
      selectedConditions[index].conditions = value;
      setIsSelect(!isSelect);
    }
  };

  const handleSendOtp = (): void => {
    sendOtp(
      {
        creditConditionId: conditionIds,
      },
      {
        onSuccess: (data) => {
          if (data.code === 200) {
            handleNextStep();
          }
        },
      }
    );
  };
  useEffect(() => {
    const totalAmounts = selectedConditions.reduce(
      (accumulator, currentValue) =>
        accumulator + Number(currentValue.conditions.totalAmount),
      0
    );

    setTotalAmount(totalAmounts);
  }, [selectedConditions.length, storeNames, isSelect]);

  useEffect(() => {
    const filterConditions: IContractNumber[] | undefined = conditions?.filter(
      (condition) =>
        condition.conditions.find((condition) => condition.isProvidedByMp)
    );

    if (filterConditions?.length) {
      const sortConditions = filterConditions?.map((condition) => ({
        storeName: condition.storeName,
        conditions: condition.conditions.find((el) => el.isProvidedByMp)!,
      }));
      setSelectedConditions((prev) => [...prev, ...sortConditions]);
    }
  }, []);

  useEffect(() => {
    if (!conditions?.length) {
      handleNextStep();
    }
  }, []);

  useEffect(() => {
    handleChangeCreditConditionId(conditionIds);
  }, [conditionIds.length]);

  return (
    <>
      <Input
        maskChar=""
        mask="999999"
        placeholder="000000"
        className="mb-3 w-[70%] sm:w-full"
        label="Номер карты Салом"
        disabled={true}
        initialValue={contractNumber}
        classNameEndIcon="!top-[38%] -translate-y-[38%]"
        endIcon={<SalomCardIcon />}
      />
      <If condition={Boolean(conditions?.length)}>
        <p className="mb-2 text-sm text-grey-500">Выберите условие</p>
        {conditions?.map((condition, index) => (
          <Accordion
            key={condition.storeName}
            title={condition.storeName}
            isOpen={storeNames.includes(condition.storeName)}
            onToggle={() => handleOpen(condition.storeName)}
          >
            <div className="grid grid-cols-6 items-center gap-2 sm:grid-cols-4">
              {sortConditions(condition.conditions)?.map((option) => (
                <div
                  key={option.id}
                  onClick={() =>
                    handleSelectValue(condition.storeName, option, index)
                  }
                  className={clsx(
                    'flex w-full cursor-pointer flex-col items-center justify-center rounded-xl border py-2 transition-all duration-200 hover:border-primary sm:w-full',
                    [
                      conditionIds?.includes(option.id)
                        ? 'border-primary'
                        : 'border-grey-20',
                    ],
                    [
                      conditionIds?.includes(option.id)
                        ? 'bg-white'
                        : 'bg-grey-20',
                    ]
                  )}
                >
                  <div className="flex flex-col items-center justify-center">
                    <p
                      className={clsx([
                        conditionIds?.includes(option.id) && 'font-semibold',
                      ])}
                    >
                      {option?.durationMax}
                    </p>
                    <span className="text-grey-500">
                      {getTypeSalomConditions(option.durationType)}
                    </span>
                  </div>
                </div>
              ))}
            </div>
            <If condition={Boolean(selectedConditions[index]?.conditions.id)}>
              <InfoPayment
                className="my-4"
                info={[
                  {
                    label: 'Комиссия',
                    value: `${numberFormat(Number(selectedConditions[index]?.conditions?.fee))} c. ( ${selectedConditions[index]?.conditions?.clientCommission}%)`,
                  },
                  {
                    label: 'Сумма к зачислению',
                    value: `${numberFormat(Number(selectedConditions[index]?.conditions?.amount))} с.`,
                  },
                  {
                    label: 'К оплате',
                    value: `${numberFormat(Number(selectedConditions[index]?.conditions?.totalAmount))} с.`,
                  },
                ]}
              />
            </If>
          </Accordion>
        ))}
      </If>
      <InfoPayment
        className="my-4"
        info={[
          {
            label: 'К оплате',
            value: `${totalAmount} с.`,
          },
        ]}
      />
      <Button
        isLoading={isPendingSendOtp}
        disabled={!conditionIds.length}
        onClick={() =>
          contractNumber && selectedConditions.length ? handleSendOtp() : null
        }
        type="submit"
        className="w-full justify-center"
      >
        Оплатить
      </Button>
    </>
  );
};
