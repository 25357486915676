import { useState } from 'react';

import { useGetLogoSalomQuery } from '@features/form-salom/api';

import { Button, If, InfoPayment, Input } from '@ui';
import { BASE_URL_LOGOS, TransactionTypes } from '@constants';
import { getTypeSalomConditions, sortConditions } from '@utils';

import { IRegistrationForm } from './types';

import { Accordion } from '../../../../shared/ui/accordion';

export const RegistrationForm = ({
  handleSubmit,
  setFieldValue,
  errors,
  isValid,
  values,
  handleNextStep,
  isLoading,
  checkout,
  checkContractNumber,
  isTokenization,
}: IRegistrationForm) => {
  const [storeNames, setStoreNames] = useState<string[]>([]);
  const handleCheckContractNumber = (): void => {
    checkContractNumber(Number(values?.contractNumber), {
      onSuccess: (data) => {
        if (data.code === 200) {
          handleNextStep();
        }
      },
    });
  };

  const { data: logo } = useGetLogoSalomQuery(values.contractNumber);

  const conditions =
    checkout?.transactionTypes.find(
      ({ groupName }) => groupName === TransactionTypes.Salom
    )?.salomConditions || [];

  const handleOpenAccordion = (name: string): void => {
    const has = storeNames.includes(name);

    if (has) {
      const filtered = storeNames.filter((name) => name !== name);
      setStoreNames(filtered);
    } else {
      setStoreNames((prev) => [...prev, name]);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <Input
          maskChar=""
          mask="999999"
          placeholder="000000"
          className="w-[70%] sm:w-full"
          label="Номер карты Салом"
          initialValue={values.contractNumber}
          classNameEndIcon="!top-[50%] -translate-y-[50%]"
          onChange={(value) => setFieldValue('contractNumber', value)}
          errorText={errors.contractNumber || logo?.payload?.detailedInfo}
          isError={Boolean(
            errors.contractNumber || logo?.payload?.detailedInfo
          )}
          endIcon={
            <If
              condition={Boolean(
                logo?.payload?.brand && logo?.payload?.brand !== 'default.png'
              )}
            >
              <img
                alt="Logo"
                width={24}
                height={24}
                src={`${BASE_URL_LOGOS}/logos/${logo?.payload?.brand}`}
              />
            </If>
          }
        />
        {conditions.map((condition) => (
          <Accordion
            key={condition.storeName}
            title={condition.storeName}
            isOpen={storeNames.includes(condition.storeName)}
            onToggle={() => handleOpenAccordion(condition.storeName)}
          >
            <div className="grid grid-cols-6 items-center gap-2 sm:grid-cols-4">
              {sortConditions(condition.conditions)?.map((option) => (
                <div
                  key={option.id}
                  className="flex w-full cursor-not-allowed flex-col items-center justify-center rounded-xl border border-grey-20 bg-grey-20 py-2 transition-all duration-200 hover:border-primary sm:w-full"
                >
                  <div className="flex flex-col items-center justify-center">
                    <p>{option?.durationMax}</p>
                    <span className="text-grey-500">
                      {getTypeSalomConditions(option.durationType)}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </Accordion>
        ))}
        <If condition={!isTokenization}>
          <InfoPayment
            group={false}
            className="mb-4"
            info={[{ label: 'К оплате', value: `${checkout?.amount} с.` }]}
          />
        </If>
        <Button
          isLoading={isLoading}
          disabled={!isValid}
          type="submit"
          onClick={() =>
            values.contractNumber ? handleCheckContractNumber() : null
          }
          className="justify-center sm:w-full"
        >
          Далее
        </Button>
      </form>
    </>
  );
};
